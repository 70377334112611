const clearHtml = html => {
    return html
        ? html
              .replace(/&nbsp;/g, ' ')
              .replace(/<nobr>/g, '')
              .replace(/<\/nobr>/g, '')
              .replace(/<br>/g, '')
              .replace(/<\/br>/g, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/(<([^>]+)>)/gi, '')
              .replace(/<\/?/g, '')
              .replace(/\/?>/, '')
              .replace(/&#8211;/g, '–')
              .replace(/&#8212;/g, '—')
              .replace(/&#8216;/g, '‘')
              .replace(/&#8217;/g, '’')
              .replace(/&#8220;/g, '“')
              .replace(/&#8221;/g, '”')
              .replace(/&#8230;/g, '...')
              .replace(/&ldquo;/g, '"')
              .replace(/&rdquo;/g, '"')
              .replace(/&rsquo;/g, "'")
              .replace(/&lt;/g, '<')
        : '';
};

export default clearHtml;
