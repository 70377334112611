import * as React from 'react';
import cx from 'classnames';
import { Heading } from '../WYSIWYG';
import Link from 'next/link';
import Image from 'next/image';
import clearHtml from '@/utils/clearHtmlElements';

export type Maybe<T> = T | null;
type MediaItem = {
    altText: Maybe<string>;
    sourceUrl: Maybe<string>;
};

type FeaturedListProps = {
    list?: Maybe<
        Array<{
            title?: Maybe<string>;
            headline?: Maybe<string>;
            subTitle?: Maybe<string>;
            uri?: Maybe<string>;
            headerColor?: Maybe<string>;
            image?: ({ __typename?: 'MediaItem' | undefined } & Pick<MediaItem, 'altText' | 'sourceUrl'>) | null | undefined;
            pageHeaderSettings?: Maybe<any>;
        }>
    >;
    isPriorityImages?: boolean;
    type?: Maybe<string>;
};

type PostItemProps = {
    entry?: Maybe<any>;
    headline?: Maybe<string>;
    index: Maybe<number>;
    length?: Maybe<number>;
    isPriorityImages?: boolean;
};

const PostItem: React.FC<PostItemProps> = props => {
    const { entry, headline, isPriorityImages } = props;
    const image = entry?.image?.sourceUrl || '/images/image-placeholder.png';
    const titleHtml = clearHtml(entry?.pageHeaderSettings?.overwriteTitle) || entry?.title;

    return (
        <div className="featured-list-item">
            <Link href={entry?.uri} passHref>
                <div
                    className="image-container relative aspect-video h-auto w-full overflow-hidden"
                    style={{ backgroundColor: entry?.pageHeaderSettings?.titleContainerBackgroundColor }}>
                    <Image
                        fill
                        sizes="(max-width: 390px) 100vw, (max-width: 768px) 33vw, 33vw"
                        src={image}
                        priority={isPriorityImages}
                        alt={entry?.image?.altText || titleHtml}
                        className={'absolute h-auto w-full'}
                    />
                </div>
                <Heading html={titleHtml} as="h2" className="h4 col-span-6 mb-2 mt-4 w-full text-black dark:text-white" />
                <div dangerouslySetInnerHTML={{ __html: headline }} className="font-normal leading-normal text-black dark:text-white" />
            </Link>
            <style>{`
                .featured-list-item a:hover h2 {
                    color: #1295d8 !important;
                }
                _::-webkit-full-page-media, _:future, :root .featured-list-item .aspect-video {
                    overflow: hidden;
                    height: 0;
                    padding-top: 56.25%;
                }
            `}</style>
        </div>
    );
};

const FeaturedList: React.FC<FeaturedListProps> = props => {
    const { list, isPriorityImages } = props;
    const _list = list ? [...list] : [];
    return (
        <div className="featured-list-container flex snap-x snap-mandatory gap-x-5 overflow-x-scroll px-4 md:justify-center md:overflow-auto">
            {_list?.map((entry, index) => {
                const { pageHeaderSettings } = entry;
                const headline = pageHeaderSettings?.postSettings?.headline;
                if (!entry.uri) return null;
                return (
                    <div key={index} className={cx(['mb-8 w-[80vw] shrink-0 snap-center md:grow md:basis-0'])}>
                        <PostItem isPriorityImages={isPriorityImages} entry={entry} index={index} length={_list.length} headline={headline} />
                    </div>
                );
            })}
        </div>
    );
};

export default FeaturedList;
